export const TRENDING_REQUEST = "TRENDING_REQUEST";
export const TRENDING_REQUEST_SUCCESS = "TRENDING_REQUEST_SUCCESS";
export const TRENDING_REQUEST_ERROR = "TRENDING_REQUEST_ERROR";

export const MOVIE_DETAIL_REQUEST = "MOVIE_DETAIL_REQUEST";
export const MOVIE_DETAIL_REQUEST_SUCCESS = "MOVIE_DETAIL_REQUEST_SUCCESS";
export const MOVIE_DETAIL_REQUEST_ERROR = "MOVIE_DETAIL_REQUEST_ERROR";

export const PEOPLE_DETAIL_REQUEST = "PEOPLE_DETAIL_REQUEST";
export const PEOPLE_DETAIL_REQUEST_SUCCESS = "PEOPLE_DETAIL_REQUEST_SUCCESS";
export const PEOPLE_DETAIL_REQUEST_ERROR = "PEOPLE_DETAIL_REQUEST_ERROR";

export const LIST_REQUEST = "LIST_REQUEST";
export const LIST_REQUEST_SUCCESS = "LIST_REQUEST_SUCCESS";
export const LIST_REQUEST_ERROR = "LIST_REQUEST_ERROR";

export const SEARCH_REQUEST = "SEARCH_REQUEST";

export const DISCOVER_REQUEST = "DISCOVER_REQUEST";
export const DISCOVER_REQUEST_SUCCESS = "DISCOVER_REQUEST_SUCCESS";
export const DISCOVER_REQUEST_ERROR = "DISCOVER_REQUEST_ERROR";
